<template>
    <div>
        <v-sheet elevation="2" class="pa-3">
            <v-alert
                v-if="!medico"
                class="ma-5"
                type="warning"
                text
            >
                Debe seleccionar a un médico.
            </v-alert>

            <div v-if="medico">
                <v-row>
                    <v-col cols="12" md="4">
                        <v-sheet elevation="3" class="mb-2 pa-2" v-if="datosMedico">
                            <div class="mb-3"><v-btn icon @click="dialogMedico=true"><v-icon>mdi-pencil</v-icon></v-btn> Consultorio {{datosMedico.consultorio}}</div>
                            <div v-html="datosMedico.observaciones" :style='{"font-size":"8pt"}'></div>
                        </v-sheet>
                    </v-col>
                    <v-col cols="12" md="8">
                        <div>

                            <v-toolbar small dense flat>
                                <v-btn small icon @click="getEvents()"><v-icon>mdi-autorenew</v-icon></v-btn>
                                <v-toolbar-title>{{this.moment(calModel).format('MMMM YYYY')}}</v-toolbar-title>
                                <v-spacer/>
                                <v-btn small icon @click="$refs.calendar.prev()"><v-icon>mdi-menu-left</v-icon></v-btn>
                                <v-btn small text @click="setHoy()">Hoy</v-btn>
                                <v-btn small text @click="tipoCal='month'" v-if="tipoCal=='day'">Mes</v-btn>
                                <v-btn small icon @click="$refs.calendar.next()"><v-icon>mdi-menu-right</v-icon></v-btn>

                                <v-progress-linear
                                    :active="loadMonthEvents"
                                    :indeterminate="loadMonthEvents"
                                    absolute
                                    bottom
                                    color="primary"
                                />
                            </v-toolbar>

                                <v-calendar
                                    v-model="calModel"
                                    ref="calendar"
                                    color="primary"
                                    :type="tipoCal"
                                    locale="es"
                                    :events="events"
                                    @change="cambiaDatos"
                                    @click:day="cambiaVista"
                                    @click:event="clickEvent"
                                    @click:time="clickCosa"
                                    interval-minutes=30

                                    :short-intervals='false'
                                    :short-months='false'
                                    :short-weekdays='false'

                                    :interval-count="intervalCount"
                                    :first-interval="firstTime*2"
                                >
                                    <template v-slot:event="{ event }">
                                        <div class="ml-1 mt-1">
                                            <v-icon small v-if="event.recibido" class="ma-1" color="white">mdi-check-all</v-icon>
                                            {{event.name}}
                                        </div>
                                    </template>
                                </v-calendar>

                        </div>

                    </v-col>

                </v-row>
            </div>
        </v-sheet>


        <cita-detalle
            :dialogModal.sync="dialogCita"
            :nuevo="nuevaCita"
            :citaId="citaId"
            :datosMedico="datosMedico"
            :estaFecha="estaFecha"
            @guardado="citaGuardada"
        />

        <detalles-medico
            :dialogModal.sync="dialogMedico"
            :datos="datosMedico"
        />
    </div>
</template>

<script>
export default {
    mounted(){
        this.tipoCal = "month"
        this.setHoy()
    },
    components:{
        'cita-detalle':()=>import('@/components/consulta/DialogConsulta'),
        'detalles-medico':()=>import('@/components/consulta/DialogDetallesMedico')
    },
    props:{
        medico:Number,
        fecha:String,
        datosMedico:Object,
    },
    data:()=>({
        calModel:"",
        loadMed:false,
        loadMonthEvents:false,
        datMed:{},
        events:[],

        tipoCal:'month',
        inicio:'',
        fin:'',

        intervalCount:48,
        firstTime:0,

        evento:false,
        cosa:false,

        dialogCita:false,
        nuevaCita:false,
        citaId:null,
        estaFecha:{},

        dialogMedico:false,

    }),
    methods:{
        cambiaDatos(a){
            this.cargaHoras()
            this.inicio = this.moment(a.start.date).format('YYYY-MM-DD')
            this.fin = this.moment(a.end.date).format('YYYY-MM-DD')
            this.getEvents()
        },
        cambiaVista(a){
            this.calModel = a.date
            this.tipoCal = 'day'
        },
        citaGuardada(){
            this.getEvents()
            this.nuevaCita=false
        },
        cargaHoras(){
            if (this.tipoCal == 'day'){
                let dia = this.moment(this.calModel).weekday()
                //let dia = this.moment(this.calModel).format("E")
                //console.log(dia)
                let fec = this.datosMedico.horarios[dia]
                let inicio = this.moment(fec.start).format('H')
                let fin = this.moment(fec.end).format('H')

                if (fin == 0){
                    fin = 24
                }
                //console.log(inicio)
                this.firstTime = inicio
                this.intervalCount = (fin - inicio)*2

            }
        },


        clickCosa(a){
            if (this.tipoCal == 'day'){

                if (!this.evento){
                    if (a.future){
                        let dia = this.moment(this.calModel).weekday()
                        let fec = this.datosMedico.horarios[dia]

                        // Si el día no está habilitado
                        if (fec.status == 0){
                            this.$swal.fire({
                                title: 'Dia no habilitado',
                                text: "Este día no está habilitado para agendar. ¿Desea continuar?",
                                icon: 'question',
                                showCancelButton: true,
                                cancelButtonText: 'NO',
                                confirmButtonText: 'SI',
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    this.nuevaCita=true
                                    this.citaId=null
                                    this.estaFecha = a
                                    this.dialogCita=true
                                }
                            })
                        }

                        if (fec.status == 1){
                            let ev2 = [...this.events]
                            let cancelado = ev2.filter(e=>e.tipo>90)
                            if (cancelado.length >0){
                                this.$swal.fire({
                                    title: 'Dia cancelado',
                                    text: "Dia marcado como cancelado. ¿Desea continuar?",
                                    icon: 'question',
                                    showCancelButton: true,
                                    cancelButtonText: 'NO',
                                    confirmButtonText: 'SI',
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        this.nuevaCita=true
                                        this.citaId=null
                                        this.estaFecha = a
                                        this.dialogCita=true
                                    }
                                })
                            } else {
                                //console.log(a)
                                this.nuevaCita=true
                                this.citaId=null
                                this.estaFecha = a
                                this.dialogCita=true
                            }
                        }

                        //this.dialogCita=true
                    } else {
                        // Citas el pasado
                        this.$swal.fire({
                            icon: 'warning',
                            title: 'No agendar',
                            text: 'No se pueden agendar citas en el pasado',
                        })
                    }
                    //console.log(a)
                }
                this.evento=false

            }
        },
        clickEvent(a){
            if (this.tipoCal == 'day'){
                //console.log('carga evento')
                if(a.event.tipo <=3){
                    this.citaId=a.event.EventId
                    this.nuevaCita=false
                    this.dialogCita=true
                }
                this.evento=true
                //console.log(a)
            }

        },
        setHoy(){
            this.calModel = this.moment(new Date()).format('YYYY-MM-DD')
        },
        async getMedicoData(){
            this.loadMed=true
            try{
                this.loadMed=false
                const req = await this.$http({
                    method:"GET",
                    url:"/consulta/getMedicoData",
                    params:{id:this.medico},
                })
                this.loadMed=false
                this.$emit("update:datosMedico", req.data[0])
                this.datMed = req.data[0]
                this.getEvents()
            }catch(err){
                this.loadMed=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async getEvents(){
            let url
            if (this.tipoCal=='month'){
                url = "/consulta/monthEvents2"
            } else {
                url = "/consulta/citasEvent2"
            }
            this.events = []
            this.loadMonthEvents=true
            try{
                this.loadMed=false
                const req = await this.$http({
                    method:"GET",
                    url:url,
                    params:{
                        medico:this.medico,
                        startDate:this.inicio,
                        endDate:this.fin,
                        view:this.tipoCal,
                    },
                })
                this.events=req.data.root
                this.loadMonthEvents=false
                this.cargaHoras()
            }catch(err){
                this.loadMonthEvents=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },
    computed:{
        /*
        datMed:{
            get(){return this.datosMedico},
            set(v){this.$emit("update:datosMedico",v)},
        }
        */
    },
    watch:{
        medico(){
            this.getMedicoData()
            this.cargaHoras()
            //console.log(this.medico)
        },
        inicio(v){
            this.$emit("update:fecha",v)
        },

    },

}
</script>

<style>

</style>